import React, { useState, useEffect, useRef } from "react"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"
import config from "../../config"

const BannerCarousel = ({
  banners = [], // Array de banners
  fallbackImage, // Imagen de fallback cuando no hay banners
  onBannerClick, // Función para manejar clics en los banners
  height = 240, // Altura del carrusel
  section, // Nombre de la sección para el alt de la imagen
}) => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0)
  const timerRef = useRef(null)

  // Función para cambiar al siguiente banner
  const nextBanner = () => {
    setCurrentBannerIndex(prevIndex => (prevIndex + 1) % banners.length)
  }

  // Función para cambiar al banner anterior
  const prevBanner = () => {
    setCurrentBannerIndex(
      prevIndex => (prevIndex - 1 + banners.length) % banners.length
    )
  }

  // Función para reiniciar el temporizador
  const resetTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current)
    timerRef.current = setInterval(() => nextBanner(), 5000)
  }

  // Manejar cambio manual
  const handleManualBannerChange = newIndex => {
    setCurrentBannerIndex(newIndex)
    resetTimer()
  }

  // Iniciar y limpiar temporizador
  useEffect(() => {
    resetTimer()
    return () => clearInterval(timerRef.current)
  }, [banners.length])

  // Renderizar contenido
  if (banners.length === 0) {
    return (
      <div className="flex relative" style={{ height, width: "100%" }}>
        <img
          className="absolute"
          src={fallbackImage}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          alt={section.name}
        />
      </div>
    )
  }

  return (
    <>
      {banners.length === 0 ? (
        section && section.portrait && section.portrait.url ? (
          <div className="flex relative" style={{ height: 240, width: "100%" }}>
            <img
              className="absolute"
              src={
                section.portrait.url.trim().startsWith("http")
                  ? section.portrait.url.trim()
                  : config.serverUri + section.portrait.url.trim()
              }
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt={section.name}
            />
          </div>
        ) : (
          <div className="flex relative" style={{ height: 240, width: "100%" }}>
            <img
              className="absolute"
              src={
                section.seccion.portrait.url.trim().startsWith("http")
                  ? section.seccion.portrait.url.trim()
                  : config.serverUri + section.seccion.portrait.url.trim()
              }
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt={section.name}
            />
          </div>
        )
      ) : (
        <>
          {/* Flecha izquierda */}
          {currentBannerIndex > 0 && (
            <div
              className="absolute left-8 top-1/2 transform -translate-y-1/2 z-10 shadow-lg"
              onClick={() => {
                prevBanner()
                resetTimer() // Resetear el temporizador al hacer clic manualmente
              }}
              style={{
                background: "none",
                border: "none",
                padding: "15px",
                cursor: "pointer",
              }}
            >
              <KeyboardArrowLeft
                style={{
                  fontSize: 90, // Flechas mucho más grandes
                  color: "white",
                }}
              />
            </div>
          )}

          {/* Contenedor de las imágenes con animación */}
          <div
            className="flex transition-transform duration-700 ease-in-out relative"
            style={{
              transform: `translateX(-${currentBannerIndex * 100}%)`,
              width: `${banners.length * 100}%`, // Ancho proporcional al número de banners
              height: "auto", // El contenedor se adapta al tamaño de la imagen
            }}
          >
            {banners.map((banner, index) => (
              <div
                key={index}
                className="flex-shrink-0 relative"
                style={{
                  width: "100%",
                  position: "relative",
                  cursor:
                    banner.haveRoute && !banner.showRouteButton
                      ? "pointer"
                      : "default",
                  height: "auto", // Ajustar altura según la imagen
                }}
                onClick={() => onBannerClick(banner)}
              >
                {/* Imagen con lógica para hacerla clicable si banner.showRouteButton es false pero haveRoute es true */}
                <img
                  className="w-full object-cover"
                  src={
                    banner?.desktopImage.url.trim().startsWith("http")
                      ? banner?.desktopImage.url.trim()
                      : config.serverUri + banner?.desktopImage.url.trim()
                  }
                  alt={section.name}
                  style={{
                    position: "relative",
                    zIndex: 0,
                    height: "auto", // La imagen debe determinar la altura
                    marginBottom: "0px",
                  }}
                />

                {/* Contenedor centrado para el título y el botón */}
                <div
                  className="absolute inset-0 flex flex-col justify-center items-center sm:pb-[50px]"
                  style={{ zIndex: 2 }}
                >
                  {/* Título centrado con sombra */}
                  {/* Título centrado con sombra */}
                  {banner.haveTitle && banner.title && (
                    <p
                      className="text-white text-2xl font-bold mb-4"
                      style={{
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      {banner.title}
                    </p>
                  )}

                  {/* Botón centrado, redondo, más pequeño y con sombra */}
                  {banner.showRouteButton && (
                    <button
                      onClick={() => onBannerClick(banner)}
                      className="bg-black text-white py-1 px-4 rounded-full shadow-lg hover:bg-gray-900"
                    >
                      Ingresar ahora
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Flecha derecha */}
          {currentBannerIndex < banners.length - 1 && (
            <div
              className="absolute right-8 top-1/2 transform -translate-y-1/2 z-10 shadow-lg"
              onClick={() => {
                nextBanner()
                resetTimer() // Resetear el temporizador al hacer clic manualmente
              }}
              style={{
                background: "none",
                border: "none",
                padding: "15px",
                cursor: "pointer",
              }}
            >
              <KeyboardArrowRight
                style={{
                  fontSize: 90, // Flechas mucho más grandes
                  color: "white",
                }}
              />
            </div>
          )}

          {/* Indicadores de los banners (círculos) */}
          <div
            className="absolute flex justify-center w-full bottom-4"
            style={{ zIndex: 2 }} // Los círculos se colocan sobre la imagen
          >
            {banners.map((_, index) => (
              <div
                key={index}
                className={`h-3 w-3 mx-2 rounded-full cursor-pointer ${
                  index === currentBannerIndex
                    ? "bg-sc-primary"
                    : "bg-gray-300 hover:bg-gray-400"
                }`}
                onClick={() => handleManualBannerChange(index)}
              />
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default BannerCarousel
