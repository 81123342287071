import React, { useEffect, useState, useRef } from "react";
import Block from "./block";
import config, { firebaseInit } from "../../config";
import { getSuggestions, getOneBlock } from "../../api/content";
import FilterButton from "../buttons/FilterButton.js";
import {
  ContactSupportOutlined,
  SettingsBackupRestoreRounded,
} from "@material-ui/icons";
import { navigate } from "gatsby-link";
import SignInModal from "../modals/SignInModal";
import PaymentMethodModal from "../modals/PaymentMethodModal";
import NewPaymentFormModal from "../modals/NewPaymentFormModal";
import SubscriptionMessageModal from "../modals/SubscriptionMessageModal";
import CvvModal from "../forms/payments/cvvModal";
import showPerfilForm from "../modals/PerfilFormModal";
import PerfilForm from "../modals/PerfilFormModal";
import firebase from "firebase";
import DeleteMessageModal from "../modals/DeleteMessageModal";
import { StaticImage } from "gatsby-plugin-image";
import PremiumButton from "../../components/buttons/PremiumButton";
import { getUserAlerts, markAlertAsViewed } from "../../api/alertas";
import { getCarrouselForUser, getCardInfo } from "../../api/carrousel";
import { getBannersPublicitariosForUser } from "../../api/bannersPublicitarios";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { getCategories, getCategoryContents } from "../../api/content";
import CategoryBlock from "./categoryBlock.js";
import BannerCarousel from "./bannersCarrousel.js";
import BannerPublicitario from "./BannerPublicitario.js";
import Coaches from "../Coaches.js";

const Section = ({
  section,
  userHasSuscription,
  suscription,
  params,
  onFilterChange,
}) => {
  console.log(section);
  // let sectionId = "60f97c29ce3f0e0e5c92d2a3"
  const profileUri = config.serverUri;
  const [sectionId] = React.useState(section.titulo);
  // console.log(section.titulo)
  const [suggestions, setSuggestions] = React.useState({});
  const [SectionFiltrada, setSectionFiltrada] = React.useState(null);
  const [showSignInForm, setShowSignInForm] = React.useState(false);
  const [showPaymentMethod, setShowPaymentMethod] = React.useState(false);
  const [showNewPayment, setShowNewPayment] = React.useState(false);
  const [showSuccessful, setShowSuccessful] = React.useState(false);
  const [showCvvHelp, setShowCvvHelp] = React.useState(false);
  const [showPerfilForm, setShowPerfilForm] = React.useState(false);
  const [ispwa, setIspwa] = React.useState(null);
  const [userSuscription, setUserSuscription] = React.useState(null);
  const [showDeleteMessage, setShowDeleteMessage] = React.useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const [isApplePay, setIsApplePay] = React.useState(false);
  const [searchBlock, setSearchBlock] = React.useState(null);
  const [userId, setUserId] = useState(null);
  const [showRecommended, setShowRecommended] = React.useState(true);
  const [showOtherBlock, setShowOtherBlock] = React.useState(true);
  const [alerts, setAlerts] = useState([]);
  const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
  const [alertViewed, setAlertViewed] = useState(false);
  const [banners, setBanners] = useState([]);
  const [bannersPublicitarios, setBannersPublicitarios] = useState([]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [userFirebase, setUserFirebase] = useState(undefined);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const timerRef = useRef(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [categories, setCategories] = useState([]);
console.log(section.blocks)
  useEffect(() => {
    getOneBlock(section.id).then((data) => {
      setSearchBlock(data);
    });
    getCategories()
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.error("Error al obtener las categorías:", error);
      });
  }, []);

  const onViewAllClick = () => {
    setShowOtherBlock(false);
  };

  // Solo se ejecuta una vez

  const location = "";

  const handleCategoryClick = (categoryId) => {
    getCategoryContents(categoryId, userId)
      .then((contents) => {
        // Aquí puedes manejar cómo se mostrarán los contenidos de la categoría
        console.log(contents);
      })
      .catch((error) => {
        console.error("Error al obtener contenidos de la categoría:", error);
      });
  };

  const handleFilter = (nuevoBloque) => {
    setSectionFiltrada(nuevoBloque);
    onFilterChange(nuevoBloque.id);
  };
  useEffect(() => {
    if (!userId) return;

    getSuggestions(userId, section.id).then((data) => {
      if (section.seccion) {
        setShowRecommended(false);
      }
      data._id = section.id;
      data.titulo = "Recomendados para ti";
      data.isBlock = false;
      setSuggestions(data);
    });

    if (location == "") {
      setIspwa(false);
    } else {
      setIspwa(true);
    }

    // Lógica para obtener las alertas del usuario
    getUserAlerts(userId).then((data) => {
      if (data.length > 0) {
        setAlerts(data);
      }
    });
  }, [userId]);

  useEffect(() => {
    setIsUserLoaded(false);
    setUserFirebase(undefined);

    const getCurrentUser = async (user) => {
      if (user) {
        console.log(user);
        setUserFirebase(user);

        try {
          const response = await fetch(
            `${config.serverUri}/users?email=${user.email}`
          );

          if (response.ok) {
            const data = await response.json();
            const userAccount = data[0];
            setUserId(userAccount.id);
            setIsUserLoaded(true);

            // Verificar y actualizar el campo "tecnologia"
            if (userAccount.tecnologia !== "SITIO WEB") {
              try {
                const updateResponse = await fetch(
                  `${config.serverUri}/users/${userAccount.id}`,
                  {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ tecnologia: "SITIO WEB" }),
                  }
                );

                if (!updateResponse.ok) {
                  console.error("Error al actualizar el campo tecnologia");
                }
              } catch (updateError) {
                console.error("Error en la actualización: ", updateError);
              }
            }
          } else {
            setIsUserLoaded(true);
            console.error("Error al obtener el usuario por correo electrónico");
          }
        } catch (error) {
          console.error("Error en la petición: ", error);
        }
      } else {
        console.log("No hay usuario autenticado");
        setIsUserLoaded(true); // Marcar como cargado aunque no haya usuario autenticado
      }

      // Marcar que la autenticación ya no está cargando
      setIsAuthLoading(false);
    };

    // Usar onAuthStateChanged para asegurarse de que se restaura el estado de autenticación
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      getCurrentUser(user);
    });

    // Limpiar el suscriptor cuando se desmonte el componente
    return () => unsubscribe();
  }, []); // Solo se ejecuta una vez

  const fetchCarrouselData = async () => {
    try {
      const data = await getCarrouselForUser(section.id, userId);
      console.log("Data recibida:", data); // Verificar el formato de la data

      if (data.status === "Success" && Array.isArray(data.data)) {
        console.log("Data es válida y es un array");

        // Procesar los banners y obtener detalles adicionales si es necesario
        const bannersWithDetails = await Promise.all(
          data.data.map(async (banner) => {
            console.log("Procesando banner:", banner); // Log para cada banner
            if (banner.haveRoute && banner.routeType === "Interno") {
              console.log(
                "El banner tiene ruta, intentando obtener detalles:",
                banner.route
              );
              try {
                const cardDetails = await getCardInfo(banner.route);
                console.log("Detalles de la tarjeta obtenidos:", cardDetails);
                return { ...banner, cardDetails };
              } catch (error) {
                console.error("Error al obtener la tarjeta:", error);
                return banner;
              }
            } else {
              console.log(banner);
              return banner;
            }
          })
        );

        // Solo actualizar los banners si hay datos nuevos
        setBanners(bannersWithDetails);
        console.log(section && section.portrait && section.portrait.url);
        console.log(section.seccion.portrait.url);
      } else {
        console.error(
          "Estructura de data no válida o data.data no es un array"
        );
      }
    } catch (error) {
      console.error("Error en Promise.all:", error);
    }
  };

  const fetchBannersPublicitariosData = async () => {
    try {
      const data = await getBannersPublicitariosForUser(section.id, userId);
      console.log("Data recibida:", data); // Verificar el formato de la data

      if (data.status === "Success" && Array.isArray(data.data)) {
        console.log("Data es válida y es un array");

        // Procesar los banners y obtener detalles adicionales si es necesario
        const bannersWithDetails = await Promise.all(
          data.data.map(async (banner) => {
            console.log("Procesando banner:", banner); // Log para cada banner
            if (banner.haveRoute && banner.routeType === "Interno") {
              console.log(
                "El banner tiene ruta, intentando obtener detalles:",
                banner.route
              );
              try {
                const cardDetails = await getCardInfo(banner.route);
                console.log("Detalles de la tarjeta obtenidos:", cardDetails);
                return { ...banner, cardDetails };
              } catch (error) {
                console.error("Error al obtener la tarjeta:", error);
                return banner;
              }
            } else {
              console.log(banner);
              return banner;
            }
          })
        );

        // Solo actualizar los banners si hay datos nuevos
        setBannersPublicitarios(bannersWithDetails);
      } else {
        console.error(
          "Estructura de data no válida o data.data no es un array"
        );
      }
    } catch (error) {
      console.error("Error en Promise.all:", error);
    }
  };

  // Segundo useEffect para banners
  useEffect(() => {
    if (!isUserLoaded || !section) {
      // No ejecutar si el usuario no ha sido cargado o si la sección no está definida
      return;
    }

    // Limpiar los banners al iniciar
    setBanners([]);
    setBannersPublicitarios([]);
    console.log("useEffect para banners ejecutado");

    // Función para obtener los banners
    fetchCarrouselData();
    fetchBannersPublicitariosData();
  }, [isUserLoaded, section]); // Dependencias: `isUserLoaded`, `section` y `userId`

  const handleBannerClick = (banner) => {
    if (banner.haveRoute && banner.cardDetails) {
      const { tipoDeContenido, entrenamiento, audio, plan, live } =
        banner.cardDetails;

      // Buscamos en las propiedades disponibles el valor de url_slug
      const url_slug =
        entrenamiento?.url_slug ||
        audio?.url_slug ||
        plan?.url_slug ||
        live?.url_slug;

      if (url_slug) {
        // Redirigimos al URL generado con tipoDeContenido y url_slug
        const contentUrl = `/content/${tipoDeContenido}s/${url_slug}`;
        navigate(contentUrl);
      } else {
        console.error("No se encontró un url_slug válido.");
      }
    } else if (banner.routeType === "Externo") {
      window.open(banner.route, "_blank", "noopener");
    } else {
      navigate(banner.route);
    }
  };

  const handleBannerPublicitarioClick = (banner) => {
    if (!banner || !banner.haveRoute) return;

    if (banner.haveRoute && banner.cardDetails) {
      const { tipoDeContenido, entrenamiento, audio, plan, live } =
        banner.cardDetails;

      // Buscamos en las propiedades disponibles el valor de url_slug
      const url_slug =
        entrenamiento?.url_slug ||
        audio?.url_slug ||
        plan?.url_slug ||
        live?.url_slug;

      if (url_slug) {
        // Redirigimos al URL generado con tipoDeContenido y url_slug
        const contentUrl = `/content/${tipoDeContenido}s/${url_slug}`;
        navigate(contentUrl);
      } else {
        console.error("No se encontró un url_slug válido.");
      }
    } else if (banner.routeType === "Externo") {
      window.open(banner.route, "_blank", "noopener");
    }
  }

  const handleAlertClick = async (alert) => {
    if (!alert || !alert.haveRoute) return;

    if (alert.routeType === "Interno") {
      try {
        const cardDetails = await getCardInfo(alert.route);

        if (cardDetails) {
          const { tipoDeContenido, entrenamiento, audio, plan, live } =
            cardDetails;

          const url_slug =
            entrenamiento?.url_slug ||
            audio?.url_slug ||
            plan?.url_slug ||
            live?.url_slug;

          if (url_slug) {
            const contentUrl = `/content/${tipoDeContenido}s/${url_slug}`;
            if (!alertViewed) {
              markAlertAsViewed(userId, alert._id)
                .then(() => setAlertViewed(true))
                .catch((error) =>
                  console.error("Error al marcar alerta como vista:", error)
                );
            }
            navigate(contentUrl);
          } else {
            console.error("No se encontró un url_slug válido.");
          }
        } else {
          console.error("No se encontraron detalles para la ruta interna.");
          navigate(alert.route); // Fallback si no se obtienen detalles.
        }
      } catch (error) {
        console.error("Error al obtener detalles de la tarjeta:", error);
      }
    } else if (alert.routeType === "Externo") {
      if (!alertViewed) {
        markAlertAsViewed(userId, alert._id)
          .then(() => setAlertViewed(true))
          .catch((error) =>
            console.error("Error al marcar alerta como vista:", error)
          );
      }
      window.open(alert.route, "_blank", "noopener");
    }
  };

  const handleCloseAlert = () => {
    if (alerts.length > 0 && !alertViewed) {
      markAlertAsViewed(userId, alerts[0]._id).catch((error) =>
        console.error("Error al marcar alerta como vista:", error)
      );
      setAlertViewed(true);
    }
    setAlerts([]);
  };

  const fakeSuggestionsBlock = {
    titulo: "Recomendaciones para ti",
    createdAt: new Date().toISOString(),
    id: null,
    cards: suggestions,
  };

  const fakeCategoriesBlock = {
    titulo: "Categorías",
    createdAt: new Date().toISOString(),
    id: null,
    cards: categories,
  };

  return (
    <div>
      <header>
        <div>
          <div
            className="flex relative overflow-hidden"
            style={{ width: "100%" }}
          >
            {/* Mostrar imagen de sección si no hay banners */}
            <BannerCarousel
              banners={banners}
              fallbackImage={section.portrait?.url}
              onBannerClick={handleBannerClick}
              section={section}
            />
          </div>
        </div>
      </header>

      <main className="p-8 mb-18">
        <div className="filters-section">
          <FilterButton
            section={section}
            onSectionFilterChange={(e) => handleFilter(e)}
          ></FilterButton>
        </div>

        {categories.length > 0 && banners.length != 0 && (
          <CategoryBlock useDetailedLink={true} block={fakeCategoriesBlock} userHasSuscription={userHasSuscription ?? null}/>
        )}

        {userHasSuscription === "premium" && showRecommended ? (
          <Block
            key={fakeSuggestionsBlock._id}
            subtree={suggestions.isBlock}
            block={fakeSuggestionsBlock}
            sectionId={"Recomendados"}
            userHasSuscription={userHasSuscription ?? null}
            onViewAllClick={onViewAllClick}
          />
        ) : null}

        {searchBlock !== null ? (
          <>
            <Block
              subtree={true}
              block={searchBlock}
              sectionId={searchBlock.id}
              userHasSuscription={userHasSuscription ?? null}
            ></Block>
          </>
        ) : null}

        {/* Solo funciona en "index", en sections[name] no se renderiza */}

        {showOtherBlock && SectionFiltrada !== null ? (
  <>
    {SectionFiltrada.blocks.map((bloque, key) => {
      return (
        <>
          <Block
            key={key}
            subtree={SectionFiltrada.isBlock}
            block={bloque}
            sectionId={SectionFiltrada.id}
            userHasSuscription={userHasSuscription ?? null}
          />
          {bloque.titulo?.toLowerCase() === "lives" && section.titulo?.toLowerCase() === "home" && <Coaches />}
        </>
      );
    })}
  </>
) : null}


{showOtherBlock &&
  section.blocks &&
  section.blocks.length > 0 &&
  SectionFiltrada === null ? (
    <>
      {section.blocks.map((bloque, key) => {
        return (
          <>
            <Block
              key={key}
              subtree={section.isBlock}
              block={bloque}
              sectionId={section.id}
              userHasSuscription={userHasSuscription ?? null}
            ></Block>

            {/* Agregar Coaches si el título es "home" */}
            {section.titulo?.toLowerCase() === "home" && <Coaches />}
          </>
        );
      })}
    </>
  ) : null}

      </main>
      {bannersPublicitarios ? (
        <BannerPublicitario banners={bannersPublicitarios}  onBannerClick={handleBannerPublicitarioClick}/>
      ) : null}
      {alerts.length > 0 && section.titulo?.toLowerCase() == "home" && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-transparent	">
            <div className="relative bg-white w-96 h-auto rounded-lg">
              <div className="flex justify-between items-start px-2">
                <div className="flex-grow text-center">
                  <StaticImage
                    width={96}
                    alt="Shift Logo"
                    src="../../images/logo_black.png"
                    className="my-4 mx-auto"
                  />
                </div>
                <button
                  className="text-4xl font-medium text-black"
                  onClick={handleCloseAlert}
                >
                  &times;
                </button>
              </div>

              {/* Imagen de la alerta */}
              <div className="relative overflow-hidden">
                <img
                  src={alerts[0].image.url}
                  alt={alerts[0].title}
                  className="w-full h-96 object-cover rounded-b-lg mb-0"
                />
              </div>
            </div>
            {/* Condicional para mostrar el botón solo si alerts[0].route no es nulo */}
            {alerts[0].haveRoute && (
              <div className="flex justify-center w-full bg-transparent relative mt-4">
                <button
                  className="bg-sc-primary shadow-custom-shadow py-3 px-4 rounded-xl w-48 rounded-full text-white text-base"
                  onClick={() => handleAlertClick(alerts[0])}
                >
                  Más información
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <SignInModal
        show={showSignInForm}
        onClose={() => setShowSignInForm(false)}
      />
      <PaymentMethodModal
        show={showPaymentMethod}
        onClose={() => setShowPaymentMethod(false)}
        newPaymentMethod={(value) => {
          setShowNewPayment(value);
          setShowPaymentMethod(false);
        }}
        message={(value) => {
          setShowSuccessful(value);
          suscription("premium");
          setShowPaymentMethod(false);
        }}
        setIsApplePay={setIsApplePay}
      />
      <NewPaymentFormModal
        show={showNewPayment}
        onClose={() => setShowNewPayment(false)}
        back={() => {
          setShowPaymentMethod(true);
          setShowNewPayment(false);
        }}
        cvvHelp={() => setShowCvvHelp(true)}
        messageOk={(value) => {
          suscription("premium");
          setShowSuccessful(value);
          setShowNewPayment(false);
        }}
      />
      <SubscriptionMessageModal
        isApplePay={isApplePay}
        show={showSuccessful}
        onClose={() => {
          setShowSuccessful(false);
          setShowPerfilForm(true);
        }}
      />
      <CvvModal show={showCvvHelp} onClose={() => setShowCvvHelp(false)} />

      {!ispwa && userHasSuscription === "premium" ? (
        <PerfilForm
          show={showPerfilForm}
          ispwaComponent={ispwa}
          onClose={() => setShowPerfilForm(false)}
        />
      ) : null}
    </div>
  );
};

export default Section;
