import config from "../config"

const apiServiceEndpoint = config.serverUri

// Función para obtener los banners del carrusel
const getCarrouselForUser = (sectionId, userId) => {
  const device = "pc" // Fijo a 'pc' como mencionaste
  const url = `${apiServiceEndpoint}/banners-carrousel/user/${sectionId}?device=${device}`
  
  // Agregar userId solo si está definido
  const finalUrl = userId ? `${url}&userId=${userId}` : url

  return new Promise((resolve, reject) => {
    fetch(finalUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => resolve(data))
        } else {
          response.json().then(data => reject(data))
        }
      })
      .catch(error => reject(error))
  })
}

// Función para obtener los detalles de la tarjeta (contenido) usando el cardId (route del banner)
const getCardInfo = (cardId) => {
  const url = `${config.serverUri}/cards/${cardId}`

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => resolve(data))
        } else {
          response.json().then(data => reject(data))
        }
      })
      .catch(error => reject(error))
  })
}

// Función para obtener tanto el carrusel como la tarjeta en paralelo (opcional si se quiere hacer de manera conjunta)
const getCarrouselAndCard = async (sectionId, userId, cardId) => {
  try {
    const [carrousel, card] = await Promise.all([
      getCarrouselForUser(sectionId, userId),
      getCardInfo(cardId)
    ])
    return { carrousel, card }
  } catch (error) {
    throw error
  }
}

export { getCarrouselForUser, getCardInfo, getCarrouselAndCard }
