import React, { useEffect, useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import config from "../config"

const Coaches = () => {
  const [coaches, setCoaches] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchCoaches = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(`${config.serverUri}/coaches`)
        const data = await response.json()
        setCoaches(data)
      } catch (error) {
        console.error("Error fetching coaches:", error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchCoaches()
  }, [])

  const getImageUrl = (url) => {
    if (!url) return null
    return url.startsWith("http") ? url : `${config.serverUri}${url}`
  }

  // Flecha Izquierda
  const LeftArrow = () => {
    const { scrollPrev } = useContext(VisibilityContext)
    return (
      <div onClick={() => scrollPrev()} style={{ cursor: "pointer" }}>
        <ArrowBackIosIcon />
      </div>
    )
  }

  // Flecha Derecha
  const RightArrow = () => {
    const { scrollNext } = useContext(VisibilityContext)
    return (
      <div onClick={() => scrollNext()} style={{ cursor: "pointer" }}>
        <ArrowForwardIosIcon />
      </div>
    )
  }

  if (isLoading) {
    return <p>Cargando...</p>
  }

  // Item individual
  const CoachItem = ({ coach }) => {
    const imageUrl = getImageUrl(coach.fotoPerfil?.url)
    return (
      <div
        className="w-40 flex-none m-2 text-center cursor-pointer" 
        /* 
         * w-40 => width: 10rem
         * flex-none => evita que el contenedor se encoja
         */
        onClick={() => navigate(`/coaches/${coach._id}`)}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt={coach.nombre}
            className="w-32 h-32 rounded-full object-cover mx-auto mb-2"
          />
        ) : (
          <div
            className="w-32 h-32 rounded-full bg-gray-300 flex items-center justify-center mx-auto mb-2"
          >
            <span className="text-sm font-medium text-gray-700">
              {coach.nombre.split(" ").slice(0, 2).join(" ")}
            </span>
          </div>
        )}
        <p className="text-sm font-medium">{coach.nombre}</p>
      </div>
    )
  }

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-sc-titles">Coaches</h3>
        {coaches.length > 0 && (
          <Link to="/coaches/ver-todo" style={{ cursor: "pointer" }}>
            Ver todo
          </Link>
        )}
      </div>

      {/* Desktop: usa ScrollMenu */}
      <div className="hidden md:block">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {coaches.map((coach) => (
            <CoachItem key={coach._id} coach={coach} itemId={coach._id} />
          ))}
        </ScrollMenu>
      </div>

      {/* Móvil: scroll horizontal nativo */}
      <div className="block md:hidden flex overflow-x-scroll w-full scroll-hidden">
        {coaches.map((coach) => (
          <CoachItem key={coach._id} coach={coach} />
        ))}
      </div>
    </div>
  )
}

export default Coaches
