import React from 'react';
import config from "../../config"

const BannerPublicitario = ({ banners, onBannerClick }) => {
  // Verificar si hay banners y tomar el primero
  const banner = banners?.[0];
  console.log(banners)

  // No mostrar nada si no hay banners
  if (!banner) {
    return null;
  }
  
  return (
    <div
      className="relative w-full h-auto cursor-pointer mx-0 px-0 mb-16"
      onClick={() => onBannerClick(banner)}
      style={{ cursor: banner.haveRoute ? "pointer" : "default" }}
      >
      <img
        className="w-full h-auto object-cover"
        src={
          banner.image.url.trim().startsWith("http")
            ? banner.image.url.trim()
            : config.serverUri + banner.image.url.trim()
        }
        alt={banner.title}
      />
    </div>
  );
};

export default BannerPublicitario;
