import React, { useState, useEffect, useCallback, useMemo } from "react"
import { Link } from "gatsby"
import ContentCard from "./contentCard"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { ArrowBackIos } from "@material-ui/icons"
import { StaticImage } from "gatsby-plugin-image"
import { getBlockPage } from "../../api/content"

// Componentes LeftArrow y RightArrow definidos fuera de callbacks
const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)
  return (
    <ArrowBackIos
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
    />
  )
}

const RightArrow = ({ addNewCard, isLoading }) => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)
  return (
    <ArrowForwardIosIcon
      disabled={isLastItemVisible || isLoading}
      onClick={() => {
        addNewCard()
        scrollNext()
      }}
    />
  )
}

// Componente Card memorizado para evitar renders innecesarios
const Card = React.memo(
  ({ onClick, selected, tarjeta, userHasSuscription }) => {
    return (
      <div className="mr-4">
        <ContentCard
          userHasSuscription={userHasSuscription}
          card={tarjeta}
        />
      </div>
    )
  }
)

const Block = ({
  userHasSuscription,
  block,
  subtree,
  sectionId,
  onViewAllClick,
}) => {
  const regex = /[0-9]/
  const [section] = useState(
    sectionId === "Entrenamiento" || sectionId === "Home"
      ? "60f97c29ce3f0e0e5c92d2a3"
      : "65301f7b965e9b12688fd049"
  )
  const [cards, setCards] = useState([])
  const [selected, setSelected] = useState([])
  const [actualPage, setActualPage] = useState(3)
  const [isOnLimit, setIsOnLimit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showOtherBlock, setShowOtherBlock] = useState(true)

  // Inicializamos las tarjetas en base al bloque recibido
  useEffect(() => {
    if (Array.isArray(block.cards)) {
      setCards(block.cards);
    } else if (Array.isArray(block.entrenamientos)) {
      setCards(block.entrenamientos);
    } else {
      setCards([]); // Valor por defecto en caso de que ninguno sea un array
    }
  }, [block.cards, block.entrenamientos]);
  
  // Memoriza la verificación de si un ítem está seleccionado
  const isItemSelected = useCallback(
    id => !!selected.find(el => el === id),
    [selected]
  )

  // Función para manejar la selección de una tarjeta
  const handleClick = useCallback(
    id => () => {
      const itemSelected = isItemSelected(id)
      setSelected(currentSelected =>
        itemSelected
          ? currentSelected.filter(el => el !== id)
          : currentSelected.concat(id)
      )
    },
    [isItemSelected]
  )

  // Función para agregar nuevas tarjetas (paginación)
  const addNewCard = useCallback(() => {
    if (isOnLimit || block.titulo === "Recomendados para ti" || isLoading) {
      return
    }
    setIsLoading(true)
    getBlockPage(block.id, sectionId, actualPage).then(data => {
      setIsOnLimit(!data.pagination.hasMorePages)
      setActualPage(prev => prev + 1)
      setCards(prevCards => [...prevCards, ...data.cards])
      setIsLoading(false)
    })
  }, [isOnLimit, block, sectionId, actualPage, isLoading])

  // Función para "Ver todo"
  const onViewAllClickSend = useCallback(() => {
    setShowOtherBlock(false)
    onViewAllClick()
  }, [onViewAllClick])

  // Memoriza la lista de tarjetas renderizadas
  const renderedCards = useMemo(
    () =>
      cards.map((tarjeta, key) => (
        <Card
          key={tarjeta._id || key}
          itemId={tarjeta._id}
          onClick={handleClick(tarjeta.id)}
          selected={isItemSelected(tarjeta.id)}
          tarjeta={tarjeta}
          userHasSuscription={userHasSuscription}
        />
      )),
    [cards, handleClick, isItemSelected, userHasSuscription]
  )

  // Memoriza el ScrollMenu para evitar re-renderizados innecesarios
  const horizontalScroll = useMemo(() => {
    return (
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={() => (
          <RightArrow addNewCard={addNewCard} isLoading={isLoading} />
        )}
      >
        {renderedCards}
      </ScrollMenu>
    )
  }, [renderedCards, addNewCard, isLoading])

  return (
    <div className="mb-8">
      <div className="flex w-full justify-between mb-4">
        <h3 className="text-sc-titles">{block.titulo}</h3>
        {cards && cards.length > 0 && !subtree ? (
          <>
            {sectionId !== "Recomendados" ? (
              <Link
                style={{ cursor: "pointer" }}
                to={`/sections/${
                  regex.test(sectionId) ? sectionId : section
                }/${block._id}`}
              >
                Ver todo
              </Link>
            ) : (
              showOtherBlock && (
                <button
                  style={{ cursor: "pointer" }}
                  onClick={onViewAllClickSend}
                >
                  Ver todo
                </button>
              )
            )}
          </>
        ) : null}
      </div>

      {cards && cards.length > 0 ? (
        <div>
          <div className="hidden md:block">{horizontalScroll}</div>
          <div className="block md:hidden flex overflow-x-scroll w-full scroll-hidden">
            {cards.map((card, key) => (
              <div className="mr-4" key={key}>
                <ContentCard
                  userHasSuscription={userHasSuscription ?? null}
                  card={card}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <StaticImage
          alt="SHIFT"
          src="../../images/logo_gray.png"
          className="w-32 h-10 p-2 mr-4 mt-0"
        />
      )}
    </div>
  )
}

// Comparador personalizado para React.memo: se renderiza Block sólo si cambian las props relevantes
function areEqual(prevProps, nextProps) {
  return (
    prevProps.block === nextProps.block &&
    prevProps.userHasSuscription === nextProps.userHasSuscription &&
    prevProps.subtree === nextProps.subtree &&
    prevProps.sectionId === nextProps.sectionId &&
    prevProps.onViewAllClick === nextProps.onViewAllClick
  )
}

export default React.memo(Block, areEqual)
